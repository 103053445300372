<template>
  <div>

    <el-container>
      <el-header style="background-color:#FFF;">
        <el-row class="pt-2 pb-2">
          <el-col :lg="20" :xs="14">
          <h3 style="line-height:1.8">Haftungsausschluss</h3>
          </el-col>
          <el-col :lg="2" :xs="4" style="line-height:50px; text-align:right">
            <el-button @click="$router.push({ name: 'dashboard' } )" class="mr-3 pl-3 pr-3 pt-2 pb-2" type="warning" icon="el-icon-s-home" ></el-button>
          </el-col>
          <el-col :lg="2" :xs="6" class="text-right align-content-end">
            <main-menu></main-menu>
          </el-col>
        </el-row>
      </el-header>
      <div class="w-100" style="background-color:#f39200; height:8px;"></div>
      <el-main>
        <el-row >
          <el-col :span="24">

            <el-card>

              <el-row :gutter="20">
                <el-col style="word-break: break-word;" :xs="24" :md="12">
                  <h3>Haftungsausschluss</h3>
                  Die Software Dacy dient der Dokumentation von Tumorbehandlungen. Die in der Software angebotenen wissenschaftlichen, therapeutischen und pharmakologischen Informationen (hier als Knowledebase präsentiert) dienen ausschließlich zur Orientierung und nicht der Therapieplanung. Trotz gründlicher Recherche kann keine Gewähr für die Vollständigkeit und Richtigkeit dieser Informationen gegeben werden. Die Benutzer der Software/Knowledgebase sind aufgefordert fehlende oder falsche Informationen der Redaktion über die <a href="https://dacy.pro/#Kontakt" target="_blank">Benachrichtigungsfunktion</a> der Software mitzuteilen. Die behandelnden Ärzte sind verpflichtet die von ihnen durchführten Behandlungen entsprechend der Leitlinien der Fachgesellschaften, der Fachinformationen und auf Grundlage des aktuellen Standes decr Wisssenschaft durchzuführen. Die Dacy GmbH schließt jegliche Haftung für die Behandlung von Patienten auf Grundlage des Fehlens oder der Fehlerhaftigkeit der hier präsentierten Informationen aus.
                </el-col>
                <el-col style="word-break: break-word;" :xs="24" :md="12">
                  <h3>Datenschutzerklärung</h3>
                  Diese Seite speichert keinerlei Daten von Ihnen persönlich. Keine Cookies. Keine externen Dienste. Es werden ausschließlich anonyme Protokolle auf dem Server geschrieben, die Rückschlüsse auf die generelle Verwendung dieser Seite zulassen.

                  Zur Datenschutzerklärung unserer Web-Präsenz: <a href="https://dacy.pro/datenschutz/" target="_blank">https://dacy.pro/datenschutz/</a>
                  <br/>
                  Für statistische Zwecke werden rechnerbezogene Daten gespeichert.
                  Je nach verwendetem Zugriffsprotokoll beinhaltet der Protokolldatensatz Angaben mit folgenden Inhalten:

                  IP-Adresse des anfordernden Rechners,
                  Datum und Uhrzeit der Anforderung,
                  vom anfordernden Rechner gewünschte Zugriffsmethode / Funktion,
                  vom anfordernden Rechner übermittelte Eingabewerte (z.B. Formulareinträge,
                  Zugriffsstatus des Web-Servers,
                  Name der angeforderten Datei (URL) sowie ggf. Netzwerkpfade, von dem aus die Datei angefordert beziehungsweise die gewünschte Funktion veranlasst wurde (Referrer).
                </el-col>
              </el-row>

            </el-card>


          </el-col>
        </el-row>
      </el-main>
    </el-container>

  </div></template>

<script>
import MainMenu from "@/components/parts/MainMenu";

export default {
  name: "About",
  props: [],
  components: {
    MainMenu
  },
  data () {
    return {
      dacyVersion: process.env.VUE_APP_DACY_VERSION
    }
  },
  mounted: function () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
}

}
</script>
<style scoped>
</style>
